import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '@/store/index';
import { BannerMessage, SitewideBanner, User, RecaptchaVisible } from '@/types/store/reducers';

export function useUser(): { user: User; loading: boolean } {
	const user = useSelector((state: AppState) => state.user);
	const loading = useSelector((state: AppState) => state.loading);
	return useMemo(
		() => ({
			user,
			loading,
		}),
		[user, loading],
	);
}

export function useBannerMessage(): BannerMessage {
	return useSelector((state: AppState) => {
		return state.bannerMessage;
	});
}

export function useSitewideBanner(): SitewideBanner {
	return useSelector((state: AppState) => {
		return state.sitewideBanner;
	});
}

export function useRecaptchaVisibleState(): RecaptchaVisible {
	return useSelector((state: AppState) => state.recaptchaVisible);
}
