import {
	RecaptchaVisible,
	SET_RECAPTCHA_VISIBLE,
	SetRecaptchaVisibleAction,
} from '../../types/store/actions';

export function setRecaptchaVisible(recaptchaVisible: RecaptchaVisible): SetRecaptchaVisibleAction {
	return {
		type: SET_RECAPTCHA_VISIBLE,
		payload: recaptchaVisible,
	};
}
