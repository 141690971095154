import Head from 'next/head';
import Script from 'next/script';
import { PropsWithChildren, ReactElement, ReactNode, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { Modal } from '@calm-web/design-system';
import { initDatadogLogs } from '@calm-web/logger';

import RecaptchaForm from '@/components/ui/Recaptcha/RecaptchaForm';
import recaptchaMessages from '@/components/ui/Recaptcha/RecaptchaForm/messages';
import { useUser, useRecaptchaVisibleState } from '@/hooks/store';
import { initAmplitude, setAmplitudeUserId } from '@/utils/analytics/amplitude';
import { initDatadogRum } from '@/utils/analytics/datadogRum';
import { initSegmentUser } from '@/utils/analytics/segment';

interface Props {
	title?: string;
	children: ReactNode;
}

function Layout({ children, title = 'Calm Partner Portal' }: Props): ReactElement {
	const { user } = useUser();
	const { formatMessage } = useIntl();
	const recaptchaVisible = useRecaptchaVisibleState();

	// Initialize any analytics libraries
	useEffect(() => {
		if (typeof window !== 'undefined') {
			initAmplitude();
			initDatadogRum();
			initDatadogLogs({
				service: 'web-partner-portal',
				version: process.env.BUILD_ID,
				env: process.env.LOG_ENV ?? 'local',
			});
			initSegmentUser().catch(() => {});
		}
	}, []);

	useEffect(() => {
		if (user?.email) {
			setAmplitudeUserId(user.email, user.accessPolicy?.allowed_user_role);
		} else {
			// Anonymize the current session
			setAmplitudeUserId(null, undefined);
		}
	}, [user]);

	return (
		<>
			<Head>
				{/* Elements in head are SSR and need hooks / redux state passed into them */}
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta property="calm:cdn-source" content="server" />
				<title>{title}</title>
			</Head>
			<Script id="segment">
				{`
					  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","user","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){if(window.analytics.initialized)return window.analytics[e].apply(window.analytics,arguments);var i=Array.prototype.slice.call(arguments);i.unshift(e);analytics.push(i);return analytics}};for(var i=0;i<analytics.methods.length;i++){var key=analytics.methods[i];analytics[key]=analytics.factory(key)}analytics.load=function(key,i){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=i};analytics._writeKey="RogyMoGrxU3o94o9FhADTEyRfHUkCqNE";;analytics.SNIPPET_VERSION="4.16.1";
  					analytics.load('${process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY}');
  					}}();
					`}
			</Script>
			{process.env.CALM_ENV === 'prod' && (
				<Script id="px-init" strategy="afterInteractive">
					{`
						(function(){
						window._pxAppId = '${process.env.NEXT_PUBLIC_PX_APP_ID}';
						var p = document.getElementsByTagName('script')[0],
							s = document.createElement('script');
						s.async = 1;
						s.src = '${process.env.APP_API_URL}/12Xk43jk/init.js';
						p.parentNode.insertBefore(s,p);
						}());
					`}
				</Script>
			)}
			{children}
			{recaptchaVisible && (
				<Modal
					canClose={false}
					isOpen={recaptchaVisible}
					closeModal={(): null => null}
					aria-label={formatMessage(recaptchaMessages.title)}
				>
					<RecaptchaForm />
				</Modal>
			)}
		</>
	);
}

export const SsgLayout = ({ children }: PropsWithChildren<{}>): JSX.Element => {
	return <Layout>{children}</Layout>;
};

const SpaLayout = ({ children }: PropsWithChildren<{}>): JSX.Element => {
	const { pathname, hash } = useLocation();

	// Automatically scrolls to top whenever pathname changes
	useEffect(() => {
		if (!hash) {
			window.scrollTo(0, 0);
		}
	}, [pathname, hash]);
	return <Layout>{children}</Layout>;
};

export default SpaLayout;
